import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { api } from './apiUrl';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor( private http: HttpClient) {
  }

  httpOption = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*'
    })
  };

  getInfo(id) {
    return this.http.get(api.url + '/' + id + '/info',  this.httpOption);
  }
}
