import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { api } from './apiUrl';

@Injectable({
  providedIn: 'root'
})
export class FormulaireContactService {

  constructor(private http: HttpClient) { }

  httpOption = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*'
    })
  };

  getForm() {
    return this.http.get(api.url + '/form/contact',  this.httpOption);
  }

  postForm(data) {
    return this.http.post(api.url + '/form/contact', data,  this.httpOption);
  }
}
