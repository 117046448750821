import { Component, OnInit } from '@angular/core';
import {FormulaireContactService} from "../../services/FormulaireContact.service";

@Component({
  selector: 'app-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss']
})
export class ContactComponent implements OnInit {

  email:string;
  message:string;

  sending:boolean = true;
  waiting:boolean = false;
  success:boolean = false;
  fail:boolean = false;

  token:string;

  emptyEmail:boolean = false;
  wrongEmail:boolean = false;
  emptyMessage:boolean = false;
  wrongMessage:boolean = false;

  constructor(protected formContact: FormulaireContactService) { }

  ngOnInit(): void {
    this.formContact.getForm()
      .subscribe(data => {
        this.token = data['token'];
      });
  }

  submitForm(){
    if(this.validation()){
      this.sending = false;
      this.waiting = true;
      let dataForm = {'email' : this.email, 'message' : this.message, '_token' : this.token};
      this.formContact.postForm(dataForm)
        .subscribe(data => {
          console.log(data);
          this.waiting = false;
          this.success = true;
        });
    }
  }

  validation(){
    this.emptyEmail = this.email == "" || this.email == undefined;
    this.emptyMessage = this.message == "" || this.message == undefined;
    if(!this.emptyEmail){
      this.wrongEmail = !this.validateEmail(this.email);
    }
    if(!this.emptyMessage){
      this.wrongMessage = !this.validateText(this.message);
    }
    return !(this.emptyEmail || this.emptyMessage || this.wrongEmail || this.wrongMessage);
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateText(text) {
    const reTag = /<.+?>/gm;
    const reScript = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    return !reTag.test(String(text).toLowerCase()) && !reScript.test(String(text).toLowerCase());
  }

}
