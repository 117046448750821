import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { Globals } from './globals';
import { FormsModule }   from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { LogoComponent } from './components/logo/logo.component';
import { HomeComponent } from './components/home/home.component';
import { ProductionComponent, ProjetItemComponent } from './components/production/production.component';
import { ProductionHomeComponent, ProductionHomeItemComponent, ProductionBackgroundComponent} from './components/production/production-home/production-home.component';
import { CreativeComponent } from './components/creative/creative.component';
import { ProjetComponent } from './components/projet/projet.component';
import { ClearComponent } from './components/projet/clear.component';
import { ProjetByTagPipe } from './pipe/projet-by-tag.pipe';
import { ProjectFilterComponent } from './components/project-filter/project-filter.component';
import { SvgComponent } from './components/svg/svg.component';
import { PathSvgDirective } from './components/svg/path-svg.directive';
import { ZigzagComponent } from './components/svg/zigzag/zigzag.component';
import { SvgBackComponent } from './components/svg/svg-back/svg-back.component';
import { SafePipe } from './pipe/safe.pipe';
import { InfoComponent } from './components/info/info.component';
import { ContactComponent } from './components/contact/contact.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LogoComponent,
    HomeComponent,
    ProductionComponent,
    ProductionHomeComponent,
    ProductionHomeItemComponent,
    ProductionBackgroundComponent,
    ProjetItemComponent,
    CreativeComponent,
    ProjetComponent,
    ClearComponent,
    ProjetByTagPipe,
    ProjectFilterComponent,
    SvgComponent,
    PathSvgDirective,
    ZigzagComponent,
    SvgBackComponent,
    SafePipe,
    InfoComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
