import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ProductionHomeComponent} from './components/production/production-home/production-home.component';
import { ProductionComponent } from './components/production/production.component';
import { CreativeComponent } from './components/creative/creative.component';
import { ProjetComponent } from './components/projet/projet.component';
import { ClearComponent } from './components/projet/clear.component';
import { InfoComponent } from './components/info/info.component';
import { ContactComponent } from './components/contact/contact.component';


const routes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'home',
    },
    {
      path: 'home',
      component: HomeComponent,
    },
    {
      path: 'production',
      component: ProductionComponent,
      children: [
        {
          outlet: 'focus',
          path: ':slug/:id',
          component: ProjetComponent,
        },
        {
          outlet: 'focus',
          path: '',
          component: ClearComponent,
        },
        {
          outlet: 'focus',
          path: 'clear',
          component: ClearComponent,
        }
      ],
    },
    {
      path: 'production-home',
      component: ProductionHomeComponent,
      children: [
        {
          outlet: 'focus',
          path: ':slug/:id',
          component: ProjetComponent,
        },
        {
          outlet: 'focus',
          path: '',
          component: ClearComponent,
        },
        {
          outlet: 'focus',
          path: 'clear',
          component: ClearComponent,
        }
      ],
    },
    {
      path: 'production/:slug/:id',
      component: ProjetComponent,
    },
    {
      path: 'creative',
      component: CreativeComponent,
    },
    {
      path: 'creative/:slug/:id',
      component: CreativeComponent,
    },
    {
      path: 'info/:slug/:id',
      component: InfoComponent,
    },
    {
      path: 'contact',
      component: ContactComponent,
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
