import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projetByTag'
})
export class ProjetByTagPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var v = [];
    for(let i = 0; i < value.length; i++){
      for(let j = 0; j < value[i].category.length; j++){
        if(this.isIn(value[i].category[j].id, args)) v.push(value[i]);
      }
    }
    return v;
  }

  isIn(value, arr){
    for(let i = 0; i < arr.length; i++){
      if(value == arr[i]) return true;
    }
    return false;
  }
}
