import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import {MessageService } from '../../services/message.service';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'logo',
  template: `
      <div class="logo-anim" [@logoOut]="animeEnd">
          <a *ngIf="!choise" @btLeftIn [@btLeftOut]="choise" (@btLeftOut.done)="animeBtDone($event)" @fadeIn (mouseleave)="onOut()" (mouseenter)="onOver(4)" (click)="onClick('production-home')" class="bt bt-production">Production</a>
          <a *ngIf="!choise" @btRightIn @fadeIn [@btRightOut]="choise" (mouseleave)="onOut()" (mouseenter)="onOver(3)" class="bt bt-studio">Creative</a>
          <img  [src]="images[imageIndex]" alt="" [@logoOut]="{value: animeEnd, params: {decalH: decalH + 100}}" (@logoOut.done)="end($event)" *ngIf="!animeEnd">
      </div>
      <a (click)="onClick('production-home')" class="bt-production-tiny">Production</a>
    `,
  styleUrls: ['./logo.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition("void => *",[
        style({"opacity" : 0}),
        animate('600ms ease-out')
      ])
    ]),
    trigger('logoOut', [
      transition("* => void",[
        animate('300ms ease-out', style({"transform": "translateY(-{{decalH}}px)"})),
      ],  {params: {decalH: 0}})
    ]),
    trigger('btLeftIn', [
      transition("void => *",[
        style({"left" : "-500px"}),
        animate('600ms ease-out')
      ])
    ]),
    trigger('btRightIn', [
      transition("void => *",[
        style({"right" : "-500px"}),
        animate('600ms ease-out')
      ])
    ]),
    trigger('btLeftOut', [
      transition("* => void",[
        animate('300ms ease-out',  style({"left" : "-300px", "opacity" : 0}))
      ])
    ]),
    trigger('btRightOut', [
      transition("* => void",[
        animate('300ms ease-out', style({"right" : "-300px", "opacity" : 0}))
      ])
    ])
  ]
})
export class LogoComponent implements OnInit, OnDestroy {

  images = [
    "assets/logo/logo_coul_0000.png",
    "assets/logo/logo_coul_0001.png",
    "assets/logo/logo_coul_0002.png",
    "assets/logo/logo_coul_0003.png",
    "assets/logo/logo_coul_0004.png"
  ];
  imageIndex = 0;
  decalH = 0;
  btProduction;
  btStudio;
  target: string;
  logo;
  interval;
  choise = false;
  animeEnd = false;

  constructor (private el: ElementRef, protected message: MessageService, private router: Router){}
  //------------------------------------------------------------------
  ngOnInit() {
    this.logo = this.el.nativeElement.getElementsByTagName('div')[0];
    window.addEventListener('resize', this.center);
    this.center();
    this.loopImage();
  }
  //------------------------------------------------------------------
  center(){
    this.decalH = (document.documentElement.clientHeight)/2;
    this.logo.style.top = this.decalH - (this.logo.offsetHeight/2) + "px";
  }
  //------------------------------------------------------------------
  loopImage(){
    var that = this;
    this.interval = setInterval(function(){that.nextImage()}, 60);
  }
  //------------------------------------------------------------------
  nextImage(){
    this.imageIndex ++;
    if (this.imageIndex >= this.images.length) this.imageIndex = 0;
  }
  //------------------------------------------------------------------
  getImage(index){
    clearInterval(this.interval);
    if (index <= this.images.length) this.imageIndex = index;
  }
  //------------------------------------------------------------------
  onOver(index){
    this.getImage(index);
  }
  //------------------------------------------------------------------
  onOut(){
    this.loopImage();
  }
  //------------------------------------------------------------------
  onClick(target){
    this.target = target;
    this.choise = true;
  }
  //------------------------------------------------------------------
  animeBtDone(e){
    if(e.totalTime){
      this.animeEnd = true;
    }
  }
  //------------------------------------------------------------------
  end(e){
    if(e.totalTime){
      this.router.navigate([this.target]);
    }
  }
  //------------------------------------------------------------------
  ngOnDestroy(){
    clearInterval(this.interval);
  }
  //------------------------------------------------------------------
}
