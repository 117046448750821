import {Component, Input, OnInit, ElementRef, HostListener} from '@angular/core';

@Component({
  selector: 'svg-back',
  template: `
      <svg>
        <svg:path [dessin]="dessin" [attr.stroke]="stroke" [attr.stroke-width]="strokeWidth" [attr.fill]="fill" path-svg></svg:path>
      </svg>
    `,
  styleUrls: ['./svg-back.component.scss']
})
export class SvgBackComponent implements OnInit {

  @Input('stroke') stroke;
  @Input('fill') fill;
  @Input('stroke-width') strokeWidth;
  @Input('height') height;

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.draw();
  }

  constructor(private el: ElementRef) { }

  dessin;

  ngOnInit() {
    this.dessin=[[100, 0], [70,200],  [400,300], [400, 0], [100, 0]];
    this.stroke = this.stroke ? this.stroke : '#000000';
    this.fill = this.fill ? this.fill : 'transparent';
    this.strokeWidth = this.strokeWidth ? this.strokeWidth : 'transparent';
    this.draw();
  }

  draw(){
    this.dessin[1][1] = this.height - 60;
    this.dessin[2][1] = this.height;
  }

}
