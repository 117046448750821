import {Component, AfterViewInit, ViewChild, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { ProjetService} from '../../../services/projet.service';
import {Title} from "@angular/platform-browser";
import {animate, style, transition, trigger, query, stagger} from "@angular/animations";
import {Globals} from "../../../globals";
import {MessageService} from "../../../services/message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'production-home',
  template:`
    <!--production-background [list]="list"></production-background-->
    <div class="container cont">
      <div class="projet-list production-home" [@listAnimation]="list.length">
        <div class="projet-item" *ngFor="let projet of list; let indexOf=index;"><production-home-item [index]="indexOf" [projet]="projet" (notify)="focus($event)"></production-home-item></div>
      </div>
      <div id="zone-focus" #zonefocus>
        <router-outlet name="focus"></router-outlet>
      </div>
    </div>
    <a #catalogue *ngIf="!focused" class="catalogue" (click)="goCat()">Voir tous les films</a>
  `,
  styleUrls: ['production-home.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter',
          [style({ opacity: 0, height: '0' }), stagger('100ms', animate('200ms ease-out', style({ opacity: 1, height: '192px'})))],
          { optional: true }
        ),
        query(':leave',
          animate('200ms', style({ opacity: 0, height:0 })),
          { optional: true }
        )
      ])
    ])
  ]
})

export class ProductionHomeComponent implements AfterViewInit {

  list = [];
  filter = [];
  subscription;

  @ViewChild('zonefocus') zoneFocus;
  @ViewChild('catalogue') catalogue;

  //--------------------------------------------------------------------------------------------------------------------

  constructor(protected message: MessageService,
              protected projet: ProjetService,
              public activeRoute: ActivatedRoute,
              protected pageTitle: Title,
              public router: Router,
              public globals: Globals)
  {
    this.pageTitle.setTitle('production selection');
    this.subscription = this.message.getMessage().subscribe(message => {
      if(message.target == 'project-list'){
        switch(message.text){
          case 'focus' : this.focus(null); break;
          case 'blur' : this.blur(); break;
          case 'clear' : this.clear(); break;
        }
      }
    });

  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
      this.projet.getProductionHome().subscribe(projets => {
          this.list = projets as Array<object>;
      });
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngAfterViewInit(){
    let reg = new RegExp(/^\/(production-home([^/]+)?\/(.+))/);
    if(reg.test(this.router.url)){
      this.focus(null);
    }
    var that = this;
    setTimeout(function(){ that.catalogue.nativeElement.classList.add('show');},1000);
  }

  //--------------------------------------------------------------------------------------------------------------------

  refreshList(){
    this.list = [];
    for(let i = 0; i < this.globals.projetList.length; i++){
      if(this.isActive(this.globals.projetList[i].rubrique_id)) {
        if(!this.list.includes(this.globals.projetList[i])) this.list.push(this.globals.projetList[i]);
      }
    }
  }

  //--------------------------------------------------------------------------------------------------------------------

  goCat(){
    this.router.navigate(['production',{}]);
  }

  //--------------------------------------------------------------------------------------------------------------------

  isActive(value){
    for(let i = 0; i < this.filter.length; i++){
      if(value == this.filter[i]) return true;
    }

    return false;
  }

  //--------------------------------------------------------------------------------------------------------------------

  focus(event){
    if (this.zoneFocus)
      this.zoneFocus.nativeElement.classList.add('focus');
      this.catalogue.nativeElement.classList.remove('show');
  }

  //--------------------------------------------------------------------------------------------------------------------

  blur(){
    this.router.navigate([{ outlets: { focus: [ 'clear' ] }}], {relativeTo:this.activeRoute});
    this.clear();
  }

  //--------------------------------------------------------------------------------------------------------------------

  clear(){
    if(this.zoneFocus){
      this.zoneFocus.nativeElement.classList.remove('focus');
      this.catalogue.nativeElement.classList.add('show');
    }
  }

}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'production-home-item',
  template: `
    <div class="bloc-teaser" [ngClass]="{'right' : orientation, 'focus' : (index == 0), 'style1': (style > 0.25 && style <= 0.5), 'style2': (style > 0.5 && style <= 0.75), 'style3': (style > 0.75)}">
      <a [routerLink]="path" class="projet-tn" (click)="action('focus')">
        <img [src]="img" style="margin:0;" alt="">
      </a>
      <a [routerLink]="path" class="projet-legend" (click)="action('focus')">
        <h5>{{rubriqueLabel}}</h5>
        <h3>{{label}}</h3>
        <p [innerHTML]="content"></p>
      </a>
    </div>
    `,
  styleUrls: ['production-home.component.scss'],
  animations: [
    trigger('enterImg', [
      transition("void => *",[
        style({'opacity' : 0}),
        animate('300ms ease-out')
      ])
    ]),
    trigger('enterText', [
      transition("void => *",[
        style({'left' : '300px', 'opacity' : 0}),
        animate('300ms ease-out')
      ])
    ])
  ]
})

export class ProductionHomeItemComponent implements OnInit {
  @Output()
  notify:EventEmitter<string> = new EventEmitter<string>();

  @Input('projet') projet;
  @Input('index') index;

  label:string = "";
  img:string = "";
  content:string = "";
  rubriqueLabel:string = "";
  id;
  path;
  orientation:boolean = false;
  style = 0;
  subscription: Subscription;

  constructor(public globals: Globals) {
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.randomValue();
    if(this.projet.rubrique.ancestors[1].label){
      this.rubriqueLabel = this.projet.rubrique.ancestors[1].label + " > ";
    }
    this.rubriqueLabel += this.projet.rubrique.label;
    this.label = this.projet.title;
    this.id = this.projet.id;
    this.path = [{ outlets: { focus: [this.projet.slug, this.id] } }] ;
    if( this.projet.content[0]){
      this.content = this.projet.content[0].text;
      if(this.projet.content[0].media[0]){
        this.img =  this.projet.content[0].media[0].formatedfilename;
        //this.img =  this.globals.imagePath + this.projet.content[0].media[0].path +  this.projet.content[0].media[0].filename;
      }
    }
  }


  //--------------------------------------------------------------------------------------------------------------------

  randomValue(){
    let r = Math.random();
    this.style = Math.random();
    if( r > 0.5) this.orientation = true;
  }

  //--------------------------------------------------------------------------------------------------------------------

  action(){
    this.notify.emit('focus');
  }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'production-background',
  template: `
    <div #background class="background-deco">
      <div #background2 class="background-deco-2"></div>
    </div>
    `,
  styleUrls: ['production-home.component.scss']
})

export class ProductionBackgroundComponent  implements OnInit{

  @Input('list') list;

  @ViewChild('background') background;
  @ViewChild('background2') background2;

  imgs = [];
  interval;
  index = 0;
  target:boolean = false;

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngAfterViewInit(){
    //this.animation();
  }

  //--------------------------------------------------------------------------------------------------------------------

  animation(){
    var that = this;
    var anim = function(){
      if(that.list.length > 0){
        let bck = that.target ? that.background2.nativeElement : that.background.nativeElement;
        bck.style.backgroundImage = 'url(' + that.list[that.index].content[0].media[0].formatedfilename + ')';

        that.background2.nativeElement.style.opacity = !that.target ? 0 : 1;

        that.index ++;
        that.target = ! that.target;
        if(that.index == that.list.length) that.index = 0;
      }
    }
    this.interval = setInterval(anim, 3000);
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
