import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { api } from './apiUrl';

@Injectable({
  providedIn: 'root'
})
export class ProjetService {

  constructor(private http: HttpClient) { }

  httpOption = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*'
    })
  };

  getList() {
    return this.http.get(api.url,  this.httpOption);
  }
  getProjet(id) {
    return this.http.get(api.url + '/' + id + '/projet',  this.httpOption);
  }
  getTagList() {
    return this.http.get(api.url + '/tags', this.httpOption);
  }
  getNavigation() {
    return this.http.get(api.url + '/navigations', this.httpOption);
  }
  getProductionHome() {
    return this.http.get(api.url + '/production-home', this.httpOption);
  }

}
