import {Component} from '@angular/core';
import {MessageService} from "../../services/message.service";

@Component({
  selector: 'app-clear',
  template: ''
})
export class ClearComponent {

  constructor(protected message: MessageService) {
    this.message.sendMessage('clear', 'project-list');
  }

}
