import { Injectable } from '@angular/core';
import {ProjetService} from "./services/projet.service";


@Injectable()
export class Globals {
  target : null;
  tagList;
  projetList;
  loaded = false;
  imagePath:string = '/';
  navigation;

  constructor(protected projet: ProjetService) {
  }

  init(){
    this.projet.getNavigation().subscribe( nav => {
      this.navigation = nav;
      this.projet.getTagList().subscribe(list => {
          this.tagList = list;
          for (let i = 0; i< this.tagList.length; i++) this.tagList[i].active = true;
          this.projet.getList().subscribe(list => {
              this.projetList = list;
              this.loaded = true;
            });
        });
    })
  }

}
