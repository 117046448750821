import {Component, ElementRef, OnInit, ViewChild, OnDestroy, HostListener, Output, EventEmitter} from '@angular/core';
import {ProjetService} from "../../services/projet.service";
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer, Title} from "@angular/platform-browser";
import {Globals} from "../../globals";

@Component({
  selector: 'app-projet',
  templateUrl: './projet.component.html',
  styleUrls: ['./projet.component.scss']
})
export class ProjetComponent implements OnInit, OnDestroy {

  @Output()
  notify:EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('annexe') annexe;

  data;
  id;
  label:string;
  img:string;
  content = {};
  loaded:boolean = false;
  dataLoaded:boolean = false;
  annexeHeight = 0;
  interval;
  iframes;
  carouselImg = [];
  timeOfCarousel = 3000;
  boutiqueURL:string = "https://girelle-production.sumup.link/";

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.imageLoaded();
  }

  //--------------------------------------------------------------------------------------------------------------------

  constructor(public globals: Globals, protected projet: ProjetService, private route: ActivatedRoute, private el: ElementRef, private sanitizer: DomSanitizer, protected pageTitle: Title) { }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.loaded = false;
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.projet.getProjet(this.id)
        .subscribe(projet => {
          this.data = projet;
          this.pageTitle.setTitle('production - ' + this.data.title);
          this.label = this.data.title;
          this.iframes = this.data.iframe;
          for(let i = 0; i < this.data.content.length; i++) {
            this.content[this.data.content[i].type] = {'text': this.data.content[i].text, 'media' : this.data.content[i].media};
            this.dataLoaded = true;
            if(this.data.content[i].type == "annexe" && this.data.content[i].media[0]){
              this.img = this.imagePath(this.data.content[i].media[0]);
            }else{
              setTimeout(() => {this.imageLoaded()}, 500);
            }
          }
          //
          if(this.content['text'] && this.content['text'].media.length){
            for(let i = 0; i < this.content['text'].media.length; i++){
              this.content['text'].media.visible = false;
            }
           this.interval =  setInterval(() => { this.carousel(); },  this.timeOfCarousel);
          }
        });
    });
  }

  //--------------------------------------------------------------------------------------------------------------------

  imageLoaded(){
    this.annexeHeight = this.annexe.nativeElement.offsetHeight;
    this.loaded = true;
  }

  //--------------------------------------------------------------------------------------------------------------------

  imagePath(img){
    if (!img) return '';
    if(img.formatedfilename) return img.formatedfilename;
    return  this.globals.imagePath + img.path + img.filename;
  }

  //--------------------------------------------------------------------------------------------------------------------

  carousel() {
    let last = false;
    let i;
    for (i = 0; i < this.content['text'].media.length; i++) {
      if(last){
        this.content['text'].media[i].visible = true;
        break;
      }
      if(this.content['text'].media[i].visible) last = true;
      this.content['text'].media[i].visible = false;
    }
    if(!last) this.content['text'].media[0].visible = true;
  }

  //--------------------------------------------------------------------------------------------------------------------

  close(event){
    this.notify.emit('blur');
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnDestroy(){
    clearInterval(this.interval);
  }

}
