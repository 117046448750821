import {Component, OnInit} from '@angular/core';
import {MessageService } from './services/message.service';
import {Globals} from './globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'app';
  subscription;
  id = "app";
  context = null;

  constructor(protected message: MessageService, public globals: Globals) {
  }

  ngOnInit(): void {
    this.globals.init();
  }

}
