import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    private subject = new Subject<any>();

    sendMessage(message: string, id: string) {
        this.subject.next({ text: message, target: id });
    }

    sendMessageObj(message: object, id: string) {
        this.subject.next({ obj: message, target: id });
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
