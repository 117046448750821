import { Component, OnInit } from '@angular/core';
import {ProjetService} from "../../services/projet.service";
import {Globals} from "../../globals";
import {MessageService} from "../../services/message.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'project-filter',
  template: `
    <div class="projet-filter-cont">
      <div class="container">
        <div class="bloc-filtrage">
          <h1>Filtrer par genre :</h1>
          <ul class="projet-filter">
            <li [ngClass]="{'active': tag.active}" *ngFor="let tag of globals.tagList">
              <a (click)="toggleTag(tag.id)">{{tag.label}}</a>
              <ul>
                <li [ngClass]="{'active': desc.active}" *ngFor="let desc of tag.descendants">
                  <a (click)="toggleTagDesc(tag.id, desc.id)">{{desc.label}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./project-filter.component.scss']
})

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class ProjectFilterComponent implements OnInit {


  constructor(protected projet: ProjetService, public globals: Globals, private router: Router, private activeRoute: ActivatedRoute) {
    projet.getTagList().subscribe(list => {
      this.globals.tagList = list;
      activeRoute.firstChild.paramMap.subscribe(val => {
        if(val['params'].filter != undefined) {
          this.filter(JSON.parse('[' + val['params'].filter + ']'));
        }else{
          this.initTags(true);
          this.complet(true);
        }
      });
    });
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {

  }

  //--------------------------------------------------------------------------------------------------------------------

  complet(skip:boolean = false){
    let filtre = [];
    for(let i = 0; i < this.globals.tagList.length; i++){
      for(let j = 0; j < this.globals.tagList[i].descendants.length; j++){
        if(this.globals.tagList[i].descendants[j].active) filtre.push(this.globals.tagList[i].descendants[j].id);
      }
    }
    this.router.navigate(['production', {filter : filtre}], { skipLocationChange: skip} );
    //this.router.navigate([], {relativeTo: this.activeRoute, queryParams : {filter : filtre} });
  }

  //--------------------------------------------------------------------------------------------------------------------

  toggleTag(value){
    for(let i = 0; i < this.globals.tagList.length; i++){
      if(this.globals.tagList[i].id == value) {
        this.globals.tagList[i].active = !this.globals.tagList[i].active;
        for(let j = 0; j < this.globals.tagList[i].descendants.length; j++){
            this.globals.tagList[i].descendants[j].active = this.globals.tagList[i].active;
        }
      }
    }
    this.complet();
  }

  //--------------------------------------------------------------------------------------------------------------------

  toggleTagDesc(parent, value){
    for(let i = 0; i < this.globals.tagList.length; i++){
      if(this.globals.tagList[i].id == parent) {
        let test = false;
        this.globals.tagList[i].active = false;
        for(let j = 0; j < this.globals.tagList[i].descendants.length; j++){
          if(this.globals.tagList[i].descendants[j].id == value) {
            this.globals.tagList[i].descendants[j].active = !this.globals.tagList[i].descendants[j].active;
          }
          if(!test) test = this.globals.tagList[i].descendants[j].active;
        }
        if(test) this.globals.tagList[i].active = true;
      }
    }
    this.complet();
  }

  //--------------------------------------------------------------------------------------------------------------------

  initTags(value:boolean){
      for (let i = 0; i < this.globals.tagList.length; i++) {
        this.globals.tagList[i].active = value;
        for(let j = 0; j < this.globals.tagList[i].descendants.length; j++){
            this.globals.tagList[i].descendants[j].active = value;
          }
      }
  }

  //--------------------------------------------------------------------------------------------------------------------

  filter(filter){
    this.initTags(false);
    if(filter) {
      for (let i = 0; i < this.globals.tagList.length; i++) {
        for(let j = 0; j < this.globals.tagList[i].descendants.length; j++){
          for(let k = 0; k < filter.length; k++){
            if(this.globals.tagList[i].descendants[j].id == filter[k]){
              this.globals.tagList[i].active = true;
              this.globals.tagList[i].descendants[j].active = true;
            }
          }
        }
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

}
