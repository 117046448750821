import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  colors = [
    "#00d800",
    "#ff2f2f",
    "#ffff00",
    "#00e6ff",
    "#ff00c1"
  ];
  colorIndex = 0;
  target: string;
  interval;

  @ViewChild('homebkg') bkg;

  constructor(private el: ElementRef, protected pageTitle: Title) { }

  //------------------------------------------------------------------
  ngOnInit() {
    this.pageTitle.setTitle('Bienvenue');
  }
  //------------------------------------------------------------------

  ngAfterViewInit(){
    this.loopColor();
  }
  //------------------------------------------------------------------
  loopColor(){
    var that = this;
    this.interval = setInterval(function(){that.nextColor()}, 150);
  }
  //------------------------------------------------------------------
  nextColor(){
    this.colorIndex ++;
    if (this.colorIndex >= this.colors.length) this.colorIndex = 0;
    this.bkg.nativeElement.style.backgroundColor = this.colors[this.colorIndex];
  }
  //------------------------------------------------------------------
  ngOnDestroy(){
    clearInterval(this.interval);
  }
  //------------------------------------------------------------------

}
