import {Directive, HostListener, Input, OnInit, ElementRef} from '@angular/core';

@Directive({
  selector: '[path-svg]'
})
export class PathSvgDirective implements OnInit{

  constructor(private el: ElementRef) { }
  @Input('dessin') dessin;

  dots = [];
  d;

  ngOnInit() {
    this.draw();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.draw();
  }

  draw(){
    let points = this.dessin;
    this.d = '';
    for (let i = 0; i < points.length; i++) {
      this.d += ( i == 0 ? 'M ' : 'L ') + points[i][0] + ' ' + points[i][1];
    }
    this.el.nativeElement.setAttribute('d', this.d);
  }



}
