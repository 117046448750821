import {Component, ElementRef, OnInit} from '@angular/core';
import {InfoService} from "../../services/info.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  data;
  id;
  label:string;
  img:string;
  article = [];
  content = [];
  loaded:boolean = false;
  dataLoaded:boolean = false;


  constructor(protected info: InfoService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.info.getInfo(this.id)
        .subscribe(info => {
          this.data = info;
          this.label = this.data.label;
          this.content = this.data.page.content;
          this.article = this.data.page.article;
          for(let i = 0; i < this.article.length; i++) {
            this.article[i].content = this.articleContent(this.article[i].content);
          }
          console.log(this.content);
          console.log(this.article);
        });
    });
  }

  ngOnInit(): void {
  }

  articleContent(contents){
    let content = [];
    for(let i = 0; i < contents.length; i++) {
      content[contents[i].type] = {'text': contents[i].text, 'media' : contents[i].media};
    }
    return content;
  }

}
