import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'zigzag',
  template: `
      <svg>
        <svg:path [dessin]="dots" [attr.stroke]="stroke" [attr.stroke-width]="strokeWidth" [attr.fill]="fill" path-svg></svg:path>
      </svg>
    `,
  styleUrls: ['./zigzag.component.scss']
})
export class ZigzagComponent implements OnInit {

  @Input('dessin') dessin;
  @Input('stroke') stroke;
  @Input('fill') fill;
  @Input('stroke-width') strokeWidth;

  dim = [0,680];
  dots = [];

  constructor() { }

  ngOnInit() {
    this.dessin = JSON.parse(this.dessin);
    this.stroke = this.stroke ? this.stroke : '#000000';
    this.fill = this.fill ? this.fill : 'transparent';
    this.strokeWidth = this.strokeWidth ? this.strokeWidth : 'transparent';
    this.dots = this.calcul();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.dots = this.calcul();
  }

  calcul(){
    //*
    let width = document.documentElement.offsetWidth;
    let height = document.documentElement.clientHeight;
    this.decalDot((width/2)-80, 0);
    if(this.dim[0] > 0 || this.dim[1] > 0){
      let tab = [];
      for (let i = 0; i < this.dots.length; i++){
        let t = [];
        t.push( ((this.dim[0] > 0) ? Math.round((this.dots[i][0]/this.dim[0]) * width) : this.dots[i][0]) );
        t.push( ((this.dim[1] > 0) ? Math.round((this.dots[i][1]/this.dim[1]) * height) : this.dots[i][1]) );
        tab.push( t );
      }
      return tab;
    }
    return this.dessin;
  }

  decalDot(offsetX, offsetY){
    let tab = [];
    for(let i = 0; i < this.dessin.length; i++){
      tab.push([this.dessin[i][0] + offsetX, this.dessin[i][1] + offsetY]);
    }

    this.dots = tab;
  }

}
