import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'c-svg',
  template: `
      <svg>
        <svg:path [dessin]="dessin" [attr.stroke]="stroke" [attr.stroke-width]="strokeWidth" [attr.fill]="fill" path-svg></svg:path>
      </svg>
    `,
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {

  @Input('dessin') dessin;
  @Input('stroke') stroke;
  @Input('fill') fill;
  @Input('stroke-width') strokeWidth;

  constructor() { }

  ngOnInit() {
    this.stroke = this.stroke ? this.stroke : '#000000';
    this.fill = this.fill ? this.fill : 'transparent';
    this.strokeWidth = this.strokeWidth ? this.strokeWidth : 'transparent';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
  }

}
