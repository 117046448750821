import {Component, OnInit, AfterViewInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import { ProjetService} from '../../services/projet.service';
import {Title} from "@angular/platform-browser";
import {animate, state, style, transition, trigger, query, stagger} from "@angular/animations";
import {Globals} from "../../globals";
import {MessageService} from "../../services/message.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-production',
  template:`
    <div class="container">
      <div class="projet-list" [@listAnimation]="list.length">
        <div class="projet-item" *ngFor="let projet of list"><projet-item [projet]="projet" (notify)="focus($event)"></projet-item></div>
      </div>
      <div id="zone-focus" #zonefocus>
        <router-outlet name="focus"></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ['./production.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter',
          //[style({ opacity: 0, transform: 'translateX(100px)' }), stagger('100ms', animate('300ms ease-out', style({ opacity: 1, transform: 'translateX(0)'})))],
          [style({ opacity: 0, height: '0' }), stagger('100ms', animate('200ms ease-out', style({ opacity: 1, height: '192px'})))],
          { optional: true }
        ),
        query(':leave',
          animate('200ms', style({ opacity: 0, height:0 })),
          { optional: true }
        )
      ])
    ])
  ]
})

export class ProductionComponent implements AfterViewInit {

  list = [];
  filter = [];
  subscription;
  isFilter:boolean = true;

  @ViewChild('zonefocus') zoneFocus;

  //--------------------------------------------------------------------------------------------------------------------

  constructor(protected message: MessageService,
              protected projet: ProjetService,
              protected pageTitle: Title,
              public activeRoute: ActivatedRoute,
              public router: Router,
              public globals: Globals)
  {
    this.pageTitle.setTitle('production');
    this.subscription = this.message.getMessage().subscribe(message => {
      if(message.target == 'project-list'){
        switch(message.text){
          case 'refresh' : this.refreshList();; break;
          case 'focus' : this.focus(null); break;
          case 'blur' : this.blur(); break;
          case 'clear' : this.clear(); break;
        }
      }
    });
    activeRoute.paramMap.subscribe( (val) => {
      if(val['params'].filter != undefined) this.filter = JSON.parse('[' + val['params'].filter + ']');
      this.refreshList();
    });
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngAfterViewInit(){
    let reg = new RegExp(/^\/(production([^/]+)?\/(.+))/);
    if(reg.test(this.router.url)){
      this.focus(null);
    }
  }

  //--------------------------------------------------------------------------------------------------------------------

  refreshList(){
    this.list = [];
    for(let i = 0; i < this.globals.projetList.length; i++){
      if(this.isActive(this.globals.projetList[i].rubrique_id)) {
        if(!this.list.includes(this.globals.projetList[i])) this.list.push(this.globals.projetList[i]);
      }
    }
  }

  //--------------------------------------------------------------------------------------------------------------------

  isActive(value){
      for(let i = 0; i < this.filter.length; i++){
        if(value == this.filter[i]) return true;
      }

    return false;
  }

  //--------------------------------------------------------------------------------------------------------------------

  focus(event){
    this.isFilter = false;
    if (this.zoneFocus)
      this.zoneFocus.nativeElement.classList.add('focus');
  }

  //--------------------------------------------------------------------------------------------------------------------

  blur(){
    this.router.navigate([{ outlets: { focus: [ 'clear' ] }}], {relativeTo:this.activeRoute});
    this.clear();
  }

  //--------------------------------------------------------------------------------------------------------------------

  clear(){
    this.isFilter = true;
    if(this.zoneFocus)
    this.zoneFocus.nativeElement.classList.remove('focus');
  }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'projet-item',
  template: `
    <div (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()"  class="bloc-teaser" [ngClass]="{'right' : orientation, 'style1': (style > 0.25 && style <= 0.5), 'style2': (style > 0.5 && style <= 0.75), 'style3': (style > 0.75)}">
      <a [routerLink]="path" class="projet-tn" (click)="action('focus')">
        <img [src]="img" style="margin:0;" alt="">
      </a>
      <a #legend [routerLink]="path" class="projet-legend" [ngClass]="cl" (click)="action('focus')">
        <h3 #header>{{label}}</h3>
        <p [innerHTML]="content"></p>
      </a>
    </div>
    `,
  styleUrls: ['./production.component.scss'],
  animations: [
    trigger('enterImg', [
      transition("void => *",[
        style({'opacity' : 0}),
        animate('300ms ease-out')
      ])
    ]),
    trigger('enterText', [
      transition("void => *",[
        style({'left' : '300px', 'opacity' : 0}),
        animate('300ms ease-out')
      ])
    ])
  ]
})

export class ProjetItemComponent implements OnInit {
  @Output()
  notify:EventEmitter<string> = new EventEmitter<string>();

  @Input('projet') projet;

  @ViewChild('header') header;
  @ViewChild('legend') legend;

  label:string = "";
  img:string = "";
  content:string = "";
  cl:string = "";
  legendTop = 0;
  id;
  path;
  orientation:boolean = false;
  style = 0;
  subscription: Subscription;

  constructor(public globals: Globals) {
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.cl = "tn" + (Math.floor(Math.random() * 3) + 1);
    this.label = this.projet.title;
    this.id = this.projet.id;
    this.path = [{ outlets: { focus: [this.projet.slug, this.id] } }] ;
    if( this.projet.content[0]){
      this.content = this.projet.content[0].text;
      if(this.projet.content[0].media[0]){
        this.img =  this.projet.content[0].media[0].formatedfilename;
        //this.img =  this.globals.imagePath + this.projet.content[0].media[0].path +  this.projet.content[0].media[0].filename;
      }
    }
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngAfterViewInit(){
    this.legendTop = ((this.legend.nativeElement.offsetHeight - this.header.nativeElement.offsetHeight) - 8);
    this.legend.nativeElement.style.top = this.legendTop + "px";
  }

  //--------------------------------------------------------------------------------------------------------------------

  mouseEnter(){
    this.legend.nativeElement.style.top = 0;
  }

  //--------------------------------------------------------------------------------------------------------------------

  mouseLeave(){
    this.legend.nativeElement.style.top = this.legendTop + "px";
  }

  //--------------------------------------------------------------------------------------------------------------------

  action(){
    this.notify.emit('focus');
  }
}
