import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Globals} from '../../globals';
import {animate, style, transition, trigger, state} from "@angular/animations";
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {MessageService} from "../../services/message.service";

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('fallIn', [
      transition("void => *",[
        style({"transform" : 'translateY(-100px)'}),
        animate('300ms ease-out')
      ])
    ]),
    trigger('menuOffCanvas', [
      //state('true', style({ top: '0' })),
      //state('false', style({ top: '-400px' })),
      state('true', style({ top: '0' })),
      state('false', style({ transform: 'translateY(-100%)' })),
      transition('false <=> true', animate('400ms ease-out'))
    ])
  ]
})
export class MenuComponent implements OnInit {

  social = [false, false, false, false, false, false];
  animeInterval = 0;
  target: string;
  showOfCanvas = false;
  detailProjet:boolean = false;
  productionHome:boolean = false;
  topPixel:string = '-400px';

  constructor(protected message: MessageService, public globals: Globals, public router: Router, private route: ActivatedRoute, private location: Location) {
    this.parseUrl();
    route.data.subscribe(( val) => { });
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.parseUrl();
      }
    });
  }

  //--------------------------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.animationEvolution();
  }

  //--------------------------------------------------------------------------------------------------------------------

  parseUrl(){
    this.target = this.router.url.match(/^\/([^?^;^\/]+)/)[1];
    let reg = new RegExp(/^\/(prod[(uction)|(-home)]([^/]+)?\/(.+))/);
    this.detailProjet = reg.test(this.router.url);
    if(this.detailProjet){
      console.log(this.router.url.match(reg)[3]);
      let regt = new RegExp(/focus:clear/);
      this.detailProjet = !regt.test(this.router.url.match(reg)[3]);
    }

    let reg2 = new RegExp(/^\/production-home(\/)?$/);
    this.productionHome = reg2.test(this.router.url);
    this.showOfCanvas = false;
  }

  //--------------------------------------------------------------------------------------------------------------------

  animationEvolution(){
    if(!this.social[5]){
      var th = this;
      setTimeout(function(){
        th.social[th.animeInterval] = true;
        th.animeInterval ++;
        th.animationEvolution();
      }, 100);
    }
  }

  //--------------------------------------------------------------------------------------------------------------------

  ofCanvasState(state){
    this.showOfCanvas = state;
    console.log(1);
  }

  //--------------------------------------------------------------------------------------------------------------------

  back(){
    this.message.sendMessage('blur', 'project-list');
    this.location.back();
  }

  //--------------------------------------------------------------------------------------------------------------------

  navToProduction(filtre){
    this.router.navigate(['production', {filter : [filtre]}]);
  }

}
